import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import "antd/dist/reset.css";
import "./assets/css/style.css";

import { ConfigProvider, notification } from "antd";

import { store } from "./redux/store";
import { useDispatch } from "react-redux";
import { setAuthData } from "./redux/authSlice";

import { Provider } from "react-redux";

const Login = lazy(() => import("./pages/auth/login"));
const ForgotPassword = lazy(() => import("./pages/auth/forgotPassword"));
const SignUp = lazy(() => import("./pages/auth/signUp"));

const Home = lazy(() => import("./pages/main/home/index"));
const Refer = lazy(() => import("./pages/main/refer"));
const Profile = lazy(() => import("./pages/main/profile"));
const Addons = lazy(() => import("./pages/main/addons"));
const Stores = lazy(() => import("./pages/main/stores"));
const Users = lazy(() => import("./pages/main/users"));
const Plans = lazy(() => import("./pages/main/plans"));
const Automations = lazy(() => import("./pages/main/system/automations"));
const Defaults = lazy(() => import("./pages/main/system/defaults"));
const Apps = lazy(() => import("./pages/main/system/apps"));
const StoreHome = lazy(() => import("./pages/store/home"));
const StoreMenu = lazy(() => import("./pages/store/menu"));
const StoreMealDeal = lazy(() =>
  import("./pages/store/menu/mealDeal/mealDeal.js")
);

const Orders = lazy(() => import("./pages/store/orders/Orders"));
const OrdersReviews = lazy(() => import("./pages/store/orders/Reviews"));

const StorePromotions = lazy(() => import("./pages/store/promotions"));

const StoreLoyalty = lazy(() => import("./pages/store/marketing/loyalty"));
const Campaigns = lazy(() => import("./pages/store/marketing/campaigns"));
const MarketingAutomation = lazy(() =>
  import("./pages/store/marketing/automation")
);

const GeneralAccount = lazy(() =>
  import("./pages/store/settings/general/account.js")
);
const GeneralUsers = lazy(() =>
  import("./pages/store/settings/general/GeneralUsers")
);
const GeneralPlans = lazy(() => import("./pages/store/settings/general/plans"));
const GeneralPaymentCards = lazy(() =>
  import("./pages/store/settings/general/paymentCards")
);
const GeneralBillingData = lazy(() =>
  import("./pages/store/settings/general/billingData")
);

const OrderingSettings = lazy(() =>
  import("./pages/store/settings/order/Ordering")
);
const ReceivingOrdersSettings = lazy(() =>
  import("./pages/store/settings/order/ReceivingOrders")
);
const OrderFormSettings = lazy(() =>
  import("./pages/store/settings/order/OrderForm")
);
const GdprTosSettings = lazy(() =>
  import("./pages/store/settings/order/GDPR_TOS")
);
const StoreOrderingTime = lazy(() =>
  import("./pages/store/settings/order/orderingTime")
);
const StoreOrderTypes = lazy(() =>
  import("./pages/store/settings/order/orderTypes")
);
const PrintingOrdersSettings = lazy(() =>
  import("./pages/store/settings/order/Printing")
);

const RecommendedProductsSettings = lazy(() =>
  import("./pages/store/settings/order/RecommendedProducts")
);
const ReviewSettings = lazy(() =>
  import("./pages/store/settings/order/ReviewSettings")
);
const StoreWorkingHours = lazy(() =>
  import("./pages/store/settings/store/workingHours")
);
const StoreLocationContact = lazy(() =>
  import("./pages/store/settings/store/locationContact")
);
const StoreDeliveryZones = lazy(() =>
  import("./pages/store/settings/store/deliveryZones")
);
const StorePaymentTypes = lazy(() =>
  import("./pages/store/settings/store/paymentTypes")
);
const StoreBranding = lazy(() =>
  import("./pages/store/settings/store/branding")
);
const AppStores = lazy(() => import("./pages/store/settings/store/appStores"));
const StoreDesign = lazy(() => import("./pages/store/settings/store/design"));
const StoreSettings = lazy(() =>
  import("./pages/store/settings/store/storeSettings.js")
);

const ReportOrderSummary = lazy(() =>
  import("./pages/store/report/orderSummary")
);
const ReportOrderHistory = lazy(() =>
  import("./pages/store/report/orderHistory")
);
const ReportCustomers = lazy(() => import("./pages/store/report/customers"));
const StoreInvoices = lazy(() => import("./pages/store/report/storeInvoices"));

const WebsiteDashboardLayout = lazy(() =>
  import("./components/layouts/WebsiteDashboard")
);
const MainLayout = lazy(() => import("./components/layouts/MainDashboard"));

const NoMatchCmp = lazy(() => import("./components/shared/NoMatch"));
const AccessDenied = lazy(() => import("./components/shared/AccessDenied"));

import PageLoading from "./components/page_loading";

const NoMatch = () => {
  return <NoMatchCmp />;
};
function App() {
  const [api, contextHolder] = notification.useNotification();

  const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();

    // Check device type
    useEffect(() => {
      // Check device type
      let hasTouchScreen = false;
      if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
        const mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
          hasTouchScreen = !!mQ.matches;
        } else if ("orientation" in window) {
          hasTouchScreen = true; // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          var UA = navigator.userAgent;
          hasTouchScreen =
            /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
            /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
      }

      // id mobile device show a popup with alert
      if (hasTouchScreen) {
        api.warning({
          message: (
            <>
              <div className="text-lg font-bold">Mobile device detected</div>
            </>
          ),
          description:
            "We highly recommend to return to this page using a desktop computer.",
          duration: 0,
        });
      }
    }, []);

    // Check if user is logged in
    const state = store.getState();
    const token = state.auth.token;
    if (!token) {
      const location = useLocation();
      return <Navigate to="/login" replace state={{ from: location }} />;
    }


    return (
      <>
        {contextHolder}
        {children}
      </>
    );
  };

  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:token" element={<ForgotPassword />} />
          <Route path="/signup" element={<SignUp />} />

          <Route path="/access-denied" element={<AccessDenied />} />

          <Route
            element={
              <ProtectedRoute>
                <MainLayout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Home />} />
            <Route path="refer" element={<Refer />} />
            <Route path="profile" element={<Profile />} />
            <Route path="addons" element={<Addons />} />
            <Route path="stores" element={<Stores />} />
            <Route path="stores/:userId" element={<Stores />} />
            <Route path="users" element={<Users />} />
            <Route path="plans" element={<Plans />} />
            <Route path="automations" element={<Automations />} />
            <Route path="defaults" element={<Defaults />} />
            <Route path="apps" element={<Apps />} />
          </Route>

          <Route
            path="/store/admin"
            element={
              <ProtectedRoute>
                <WebsiteDashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path=":storeId" element={<StoreHome />} />
            <Route path=":storeId/menu" element={<StoreMenu />} />
            <Route path=":storeId/menu/meal_deal" element={<StoreMealDeal />} />
            <Route
              path=":storeId/menu/recommended_products"
              element={<RecommendedProductsSettings />}
            />

            <Route path=":storeId/orders" element={<Orders />} />
            <Route path=":storeId/orders/reviews" element={<OrdersReviews />} />

            <Route path=":storeId/promotions" element={<StorePromotions />} />

            <Route
              path=":storeId/marketing/loyality_program"
              element={<StoreLoyalty />}
            />
            <Route
              path=":storeId/marketing/campaigns"
              element={<Campaigns />}
            />
            <Route
              path=":storeId/marketing/marketing_automation"
              element={<MarketingAutomation />}
            />

            <Route
              path=":storeId/general/account"
              element={<GeneralAccount />}
            />
            <Route path=":storeId/general/users" element={<GeneralUsers />} />
            <Route path=":storeId/general/plans" element={<GeneralPlans />} />
            <Route
              path=":storeId/general/payment_cards"
              element={<GeneralPaymentCards />}
            />
            <Route
              path=":storeId/general/billing_data"
              element={<GeneralBillingData />}
            />
            <Route
              path=":storeId/general/printing"
              element={<PrintingOrdersSettings />}
            />
            <Route
              path=":storeId/general/gdpr_tos"
              element={<GdprTosSettings />}
            />

            <Route
              path=":storeId/order/ordering"
              element={<OrderingSettings />}
            />
            <Route
              path=":storeId/order/receiving"
              element={<ReceivingOrdersSettings />}
            />
            <Route
              path=":storeId/order/order_form"
              element={<OrderFormSettings />}
            />
            <Route
              path=":storeId/order/review_settings"
              element={<ReviewSettings />}
            />
            <Route
              path=":storeId/order/ordering_time"
              element={<StoreOrderingTime />}
            />
            <Route
              path=":storeId/order/order_types"
              element={<StoreOrderTypes />}
            />
            <Route
              path=":storeId/order/payment_types"
              element={<StorePaymentTypes />}
            />

            <Route
              path=":storeId/store/working_hours"
              element={<StoreWorkingHours />}
            />
            <Route
              path=":storeId/store/location_contact"
              element={<StoreLocationContact />}
            />
            <Route
              path=":storeId/store/delivery_zones"
              element={<StoreDeliveryZones />}
            />
            <Route path=":storeId/store/branding" element={<StoreBranding />} />
            <Route path=":storeId/store/app_stores" element={<AppStores />} />
            <Route path=":storeId/store/design" element={<StoreDesign />} />
            <Route
              path=":storeId/store/store_content"
              element={<StoreSettings />}
            />

            <Route
              path=":storeId/report/order_summary"
              element={<ReportOrderSummary />}
            />
            <Route
              path=":storeId/report/order_history"
              element={<ReportOrderHistory />}
            />
            <Route
              path=":storeId/report/customers"
              element={<ReportCustomers />}
            />
            <Route
              path=":storeId/report/store_invoices"
              element={<StoreInvoices />}
            />
          </Route>

          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

const root = createRoot(document.getElementById("loopinfoodbell"));

root.render(
  <ConfigProvider
    theme={{
      components: {
        Radio: {
          buttonSolidCheckedBg: "#f59e09",
          buttonSolidCheckedActiveBg: "#f59e09",
          buttonSolidCheckedHoverBg: "#f59e09",
        },
      },
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);
